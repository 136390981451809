import api from '..'
import { Association } from './associations'
import { Season } from './seasons'
import { Competition } from './competitions'

export interface Team {
  _id: string
  ttr_id: number
  name: string
  association: Association
  competition: Competition
  season: Season
  contactName: string
  contactEmail: string
  contactNumber: string
  uniformColour: {
    front: string
    side: string
    back: string
  }
}

export interface QueryParams {
  isAdmin?: boolean
  isOpen?: boolean
  openFor?: 'staff' | 'player'
  offset: number
  limit: number
  search: string
  currentSeason: boolean
}

type TeamsResponse = Team[]

export const divisionsApi = api.injectEndpoints({
  endpoints: build => ({
    getTeams: build.query<TeamsResponse, QueryParams>({
      query: params => {
        const {
          offset,
          limit,
          isAdmin = undefined,
          isOpen = undefined,
          openFor = undefined,
          search,
          currentSeason = false,
        } = params
        return {
          url: 'mobile/teams',
          params: {
            isAdmin,
            isOpen,
            openFor,
            offset,
            limit,
            search,
            currentSeason,
          },
        }
      },
      providesTags: ['teams'],
    }),
    getTeam: build.query<Team, string>({
      query: id => `competitions/${id}`,
      providesTags: ['team'],
    }),
    addTeam: build.mutation({
      query: body => ({
        url: 'mobile/teams',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['cart', 'teams'],
    }),
    teamUniformValidation: build.mutation<
      any,
      { teamId: string; number: string }
    >({
      query: body => ({
        url: 'team/uniform-validator',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  useGetTeamsQuery,
  useGetTeamQuery,
  useLazyGetTeamsQuery,
  useLazyGetTeamQuery,
  useAddTeamMutation,
  useTeamUniformValidationMutation,
} = divisionsApi
