import { useAddTeamMutation } from 'manage-tritag/services/api/endpoints/teams'
import { decryptData } from 'manage-tritag/utils/crypto'
import { useEffect } from 'react'

function TestHeaders() {
  const [createTeam] = useAddTeamMutation()

  useEffect(() => {
    const token = localStorage.getItem('mahiwagangTanzan')
      ? decryptData(localStorage.getItem('mahiwagangTanzan') || '')
      : null

    const playerDatails = localStorage.getItem('playerDetails')
      ? decryptData(localStorage.getItem('playerDetails') || '')
      : null

    alert(`${JSON.stringify(token)} AND ${JSON.stringify(playerDatails)}`)
    fetch()
  }, [])

  const fetch = async () => {
    try {
      await createTeam({
        name: '',
        competitionId: 1,
        contactName: '',
        contactEmail: 'teamDetails.primaryEmail',
        contactNumber: '',
      }).unwrap()
    } catch (error) {
      alert(JSON.stringify(error))
    }
  }

  return <div />
}

export default TestHeaders
